import React from 'react'
import { get } from 'lodash'
import { isExternalUrlOrHash, contentfulMapToLocalized } from "../../helpers.js"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import defaultRTRenderOptions from "../../../contentful/rich-text-renderer-options"
import {Card, Accordion } from 'react-bootstrap'
import styles from './accordion.scss'
import loadable from '@loadable/component'

const Author = loadable(() => (import('../contentful/author')))

/**
 * Declares basic functionality for Modals
 * @extends React
 */
class AccordionCustom extends React.Component {
  constructor(props) {
    super(props);
    this.itemsRefs = []
    this.props.items.forEach(( item, index ) => {
      this.itemsRefs[index] = React.createRef();
    })
    this.toggleHeaderClass = this.toggleHeaderClass.bind(this);
    this.bodyClassName = this.props.contentClassName || ''
  }
  
  /** Functions belows is a workaround because we need to know accordion status on the header and, by default, it will only show on the content **/
  toggleHeaderClass(index) {
    this.itemsRefs.map((item, i) => {
      if (item.current.classList.contains('open') && item !== this.itemsRefs[index]) item.current.classList.remove('open')
    })

    if (this.itemsRefs) this.itemsRefs[index].current.classList.toggle('open')
  }
  
  render() {
    return (
      <Accordion className={ this.props.className ? this.props.className : '' } defaultActiveKey={this.props.defaultActiveKey ? this.props.defaultActiveKey : ''}>
        {this.props.items.map((item, index) => {
          const header = get(item, 'header.json') || get(item, 'header')
          const eKey = index+ 1;
          return (
            <Card key={index}>
              <Card.Header id={`accordion-header-${index}`} ref={ this.itemsRefs[index] } >
                {/* <BootstrapAccordion.Toggle as="div" eventKey={index}> */}
                <Accordion.Toggle as="div" eventKey={eKey} onClick={this.toggleHeaderClass.bind(this, index)}>
                  { header instanceof Object === true ? documentToReactComponents(header, {}) : header } 
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={eKey}>
                <Card.Body className={this.bodyClassName}>
                  {documentToReactComponents(get(item, 'content.json') || get(item, 'content'), defaultRTRenderOptions)}
                  { get(item, 'author') &&
                    <Author author={get(item, 'author')} nameAsLink={ true }/>
                  }
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          )
        })}
      </Accordion>
    )
  }
}

export default AccordionCustom