import React from 'react'
import get from 'lodash/get'
import { isExternalUrlOrHash, contentfulMapToLocalized } from "../../helpers.js"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import HPAccordion from '../accordion/accordion'
/**
 * Declares basic functionality for Modals
 * @extends React
 */
class Accordion extends React.Component {
  constructor(props) {
    super(props);    
    const node = this.props.node
    let contentfulAccordeon = node.fields
    contentfulAccordeon = contentfulMapToLocalized(contentfulAccordeon)
    this.items = [];
    contentfulAccordeon.items.forEach(( item, index ) => {
      let cItem = contentfulMapToLocalized(item.fields)
      this.items.push(cItem)
    })    
    this.className = get(contentfulAccordeon, 'extraAttributes.className', '')
  }
  
  render() {
    return (
      <HPAccordion className={`contentful-accordion ${this.className ? this.className : ''}`} items={this.items} />
    )
  }
}

export default Accordion